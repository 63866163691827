import { useQuery } from '@tanstack/react-query';

import { useSharedDependencies } from '../../ui-config/SharedDependenciesProvider';
import { useRootStore } from '../../store/root';
import { POLLING_INTERVAL, QueryKeys } from '../../ui-config/queries';
import { EControlPositionType, IPosition } from '../../types/uniswapTokens';
import { usePositionsQuery } from './usePositionsQuery';
import { IMarketData } from './useMarketsQuery';

const INITIAL_DATA: IPosition[] = [];

const getDataProviderAddress = (marketData: IMarketData[], contractAddress: string) => {
  return marketData.find(
    (item) => item.addresses.positionManagerAddress === contractAddress
  )?.addresses.dataProviderAddress;
}

export const usePositionsDataQuery = (tokenIds?: string[]) => {
  const { uiPositionService } = useSharedDependencies();
  const { data: positions, isLoading: isLoadingPositions } = usePositionsQuery();
  const [chainId, isMarketsReady, marketData] = useRootStore((store) => [
    store.currentChainId,
    store.isMarketsReady,
    store.getCurrentChainMarketData(),
  ]);

  const requestedTokenIds = !tokenIds?.length
    ? positions.map((item) => item.token_id.toString())
    : tokenIds;

  const { data, ...rest } = useQuery({
    queryKey: [QueryKeys.POSITIONS_DATA, chainId, requestedTokenIds],
    queryFn: () => {
      const contractAddresses = positions
        .filter((item) => requestedTokenIds.includes(item.token_id.toString()))
        .map((item) => item.contract_address)
        .filter((value, index, self) => value && self.indexOf(value) === index);

      const requests = contractAddresses.map((contractAddress) => {
        const ids = positions
          .filter((item) => requestedTokenIds.includes(item.token_id.toString()))
          .filter((item) => item.contract_address === contractAddress)
          .map((item) => item.token_id);
        const dataProviderAddress = getDataProviderAddress(marketData, contractAddress);

        return dataProviderAddress
          ? uiPositionService.getPositionsData(chainId, dataProviderAddress, ids)
          : Promise.reject();
      });

      return Promise.all(requests);
    },
    enabled: isMarketsReady && !isLoadingPositions,
    refetchInterval: POLLING_INTERVAL,
    keepPreviousData: !Boolean(tokenIds?.length),
    select: (response) => response.reduce<IPosition[]>((acc, marketResponse) => {
      marketResponse.forEach((item) => {
        acc.push({
          ...item,
          controlType: EControlPositionType.ManualPosition,
          marketId: positions.find((position) => {
            return position.token_id === item.tokenId.toNumber();
          })?.market_id,
          marketName: positions.find((position) => {
            return position.token_id === item.tokenId.toNumber();
          })?.market_name,
          tokenId: item.tokenId.toNumber(),
          fee0: item.fee0.toString(),
          fee1: item.fee1.toString(),
          amount0: item.amount0.toString(),
          amount1: item.amount1.toString(),
        })
      });
      return acc;
    }, []),
  });

  return {
    data: data || INITIAL_DATA,
    ...rest,
  }
}
