import { toChecksumAddress } from 'ethereum-checksum-address';
import { ComputedReserveData } from '../hooks/app-data-provider/useAppDataProvider';

export const findTokenInReserves = ({
  reserves,
  address,
}: {
  reserves: ComputedReserveData[];
  address?: string;
}) => {
  return reserves.length && address
    ? reserves.find((item) => toChecksumAddress(item.underlyingAsset) === toChecksumAddress(address))
    : undefined;
}
