import { useRootStore } from '../../store/root';
import { useQuery } from '@tanstack/react-query';
import { FAST_POLLING_INTERVAL, QueryKeys } from '../../ui-config/queries';
import { useProtocolDataContext } from '../useProtocolDataContext';
import { EMarketName } from '../../ui-config/marketsConfig';

export interface IPositionTokensResponse {
  contract_address: string;
  token_id: number;
  chain_id: number;
}

export interface IPositionTokens {
  market_id?: number;
  market_name?: EMarketName;
  contract_address: string;
  token_id: number;
  chain_id: number;
}

const INITIAL_DATA: IPositionTokens[] = [];

let eventWasSend = false;

export const fetchPositionTokensIds = async (
  account: string,
  chainId: number,
  endpointURL: string,
) => {
  try {
    const url = `${endpointURL}/erc721/owned_tokens/${account}?chain_id=${chainId}`;
    const result = await fetch(url);
    const json: IPositionTokensResponse[] = await result.json();
    return json;
  } catch (e) {
    return [];
  }
};

export const usePositionsQuery = () => {
  const { currentNetworkConfig } = useProtocolDataContext();
  const [account, chainId, marketData] = useRootStore((store) => [
    store.account,
    store.currentChainId,
    store.getCurrentChainMarketData(),
  ]);
  const apiUrl = currentNetworkConfig.yldrYpiUrl;

  return useQuery<IPositionTokensResponse[], unknown, IPositionTokens[]>({
    queryFn: () => fetchPositionTokensIds(account, chainId, apiUrl),
    queryKey: [QueryKeys.POSITIONS, account, chainId],
    enabled: Boolean(account),
    initialData: INITIAL_DATA,
    refetchInterval: FAST_POLLING_INTERVAL,
    onSuccess: (response) => {
      const tokenIds = response.map((item) => item.token_id);
      if (tokenIds.length && !eventWasSend) {
        window.gtag('event', 'user_has_uniswap_positions', { tokenIds });
        eventWasSend = true;
      }
    },
    select: (response) => response.map((item) => ({
      ...item,
      market_id: marketData.find((market) => {
        return market.addresses.positionManagerAddress === item.contract_address;
      })?.marketId,
      market_name: marketData.find((market) => {
        return market.addresses.positionManagerAddress === item.contract_address;
      })?.marketName,
    })),
  });
}
