import BigNumber from 'bignumber.js';
import { UNISWAP_TICK } from '../ui-config/constants';

export const calculatePositionRange = (
  tick: number,
  decimalsToken0: number | undefined,
  decimalsToken1: number | undefined,
) => {
  if (!tick || !decimalsToken0 || !decimalsToken1) {
    return BigNumber(0);
  }
  return BigNumber(UNISWAP_TICK ** tick)
    .multipliedBy(BigNumber(10).pow(decimalsToken0))
    .div(BigNumber(10).pow(decimalsToken1))
    .decimalPlaces(8);
}
