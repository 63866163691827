import dayjs from 'dayjs';
import BigNumber from 'bignumber.js';
import { toChecksumAddress } from 'ethereum-checksum-address';
import { calculateCompoundedRate, SECONDS_PER_YEAR, USD_DECIMALS } from '@yldr/math-utils';

import { EPositionType, IEnrichedUniswapPosition, ILeveragedPosition, IPosition } from '../types/uniswapTokens';
import { fetchIconSymbolAndName } from '../ui-config/reservePatches';
import { calculatePositionRange } from '../utils/calculatePositionRange';
import { calculateInMarketReferenceCurrency } from '../utils/calculateInMarketReferenceCurrency';
import { valueWithDecimals } from '../utils/valueWithDecimals';
import { RootStore } from './root';
import { selectCurrentReserves, selectFormattedReserves } from './poolSelectors';

export const selectEnrichedUniswapPosition = (
  state: RootStore,
  position?: IPosition,
): IEnrichedUniswapPosition | undefined => {
  if (!position) return undefined;

  const currentReserves = selectFormattedReserves(state, dayjs().unix());
  const token0 = currentReserves.find(
    ({ underlyingAsset }) => toChecksumAddress(underlyingAsset) === toChecksumAddress(position.token0)
  );
  const token1 = currentReserves.find(
    ({ underlyingAsset }) => toChecksumAddress(underlyingAsset) === toChecksumAddress(position.token1)
  );

  if (token0 && token1) {
    const fee = BigNumber(position.fee).div(10000);
    const liquidationThresholdToken0 = BigNumber(token0.reserveLiquidationThreshold).div(10000);
    const liquidationThresholdToken1 = BigNumber(token1.reserveLiquidationThreshold).div(10000);
    const liquidationThreshold = BigNumber.min(liquidationThresholdToken0, liquidationThresholdToken1);
    const liquidationBonus = BigNumber.max(
      BigNumber(token0.reserveLiquidationBonus).div(10000),
      BigNumber(token1.reserveLiquidationBonus).div(10000),
    );

    const marketReferenceCurrencyValueToken0 = calculateInMarketReferenceCurrency(
      position.amount0,
      token0.decimals,
      token0.priceInMarketReferenceCurrency,
    );
    const marketReferenceCurrencyValueToken1 = calculateInMarketReferenceCurrency(
      position.amount1,
      token1.decimals,
      token1.priceInMarketReferenceCurrency,
    );
    const marketReferenceCurrencyValue = marketReferenceCurrencyValueToken0.plus(marketReferenceCurrencyValueToken1);
    const ltv0 = BigNumber(token0.baseLTVasCollateral).div(10000);
    const ltv1 = BigNumber(token1.baseLTVasCollateral).div(10000);
    const positionLtv = BigNumber.min(ltv0, ltv1);
    const availableUsdToken0 = calculateInMarketReferenceCurrency(
      token0.availableLiquidity,
      token0.decimals,
      token0.priceInMarketReferenceCurrency
    );
    const availableUsdToken1 = calculateInMarketReferenceCurrency(
      token1.availableLiquidity,
      token1.decimals,
      token1.priceInMarketReferenceCurrency
    );
    const maxLeverage = BigNumber(1)
      .div(BigNumber(1).minus(positionLtv))
      .multipliedBy(0.99);
    const availableLeverage0 = BigNumber.min(maxLeverage, availableUsdToken0.div(marketReferenceCurrencyValue).plus(1));
    const availableLeverage1 = BigNumber.min(maxLeverage, availableUsdToken1.div(marketReferenceCurrencyValue).plus(1));

    const liquidityToken0 = valueWithDecimals(position.amount0, token0.decimals);
    const liquidityToken1 = valueWithDecimals(position.amount1, token1.decimals);
    const feeToken0 = valueWithDecimals(position.fee0, token0.decimals);
    const feeToken1 = valueWithDecimals(position.fee1, token1.decimals);
    const priceInMarketReferenceCurrencyToken0 = valueWithDecimals(token0.priceInMarketReferenceCurrency, USD_DECIMALS);
    const priceInMarketReferenceCurrencyToken1 = valueWithDecimals(token1.priceInMarketReferenceCurrency, USD_DECIMALS);

    const netWorthToken0 = liquidityToken0.plus(feeToken0);
    const netWorthToken1 = liquidityToken1.plus(feeToken1);

    const netWorthUsdToken0 = netWorthToken0.multipliedBy(priceInMarketReferenceCurrencyToken0);

    const netWorthUsdToken1 = netWorthToken1.multipliedBy(priceInMarketReferenceCurrencyToken1);

    const lowerPosition = calculatePositionRange(position.tickLower, token0.decimals, token1.decimals);
    const upperPosition = calculatePositionRange(position.tickUpper, token0.decimals, token1.decimals);

    const sqrtPriceX96 = position.sqrtPriceX96.toString();

    const poolPrice = BigNumber(sqrtPriceX96)
      .multipliedBy(sqrtPriceX96)
      .multipliedBy(BigNumber(10).pow(token0.decimals))
      .div(BigNumber(10).pow(token1.decimals))
      .div(BigNumber(2).pow(192));

    const invertedPoolPrice = BigNumber(1).div(poolPrice);

    const positionInRange = position.tickCurrent >= position.tickLower && position.tickCurrent <= position.tickUpper;

    const feeAmount = position.fee;

    const collateral = netWorthUsdToken0.plus(netWorthUsdToken1);

    return {
      ...position,
      type: EPositionType.Position,
      fee: fee.toNumber(),
      ltv: positionLtv.toNumber(),
      liquidationThreshold: liquidationThreshold.toNumber(),
      liquidationBonus: liquidationBonus.toNumber(),
      lowerPosition,
      upperPosition,
      positionInRange,
      token0: {
        ...token0,
        underlyingAsset: toChecksumAddress(position.token0),
        decimals: token0.decimals,
        marketReferenceCurrencyValue: marketReferenceCurrencyValueToken0.toNumber(),
        availableLeverage: availableLeverage0.toNumber(),
        liquidity: liquidityToken0.toNumber(),
        priceInMarketReferenceCurrency: priceInMarketReferenceCurrencyToken0.toNumber(),
        fee: feeToken0.toNumber(),
        marketReferenceCurrencyFee: calculateInMarketReferenceCurrency(
          position.fee0,
          token0.decimals,
          token0.priceInMarketReferenceCurrency,
        ).toNumber(),
        netWorth: netWorthToken0.toNumber(),
        netWorthUsd: netWorthUsdToken0.toNumber(),
        liquidationThreshold: liquidationThresholdToken0.toNumber(),
        ...fetchIconSymbolAndName(token0),
      },
      token1: {
        ...token1,
        underlyingAsset: toChecksumAddress(token1.underlyingAsset),
        decimals: token1.decimals,
        marketReferenceCurrencyValue: marketReferenceCurrencyValueToken1.toNumber(),
        availableLeverage: availableLeverage1.toNumber(),
        liquidity: liquidityToken1.toNumber(),
        priceInMarketReferenceCurrency: priceInMarketReferenceCurrencyToken1.toNumber(),
        fee: feeToken1.toNumber(),
        marketReferenceCurrencyFee: calculateInMarketReferenceCurrency(
          position.fee1,
          token1.decimals,
          token1.priceInMarketReferenceCurrency,
        ).toNumber(),
        netWorth: netWorthToken1.toNumber(),
        netWorthUsd: netWorthUsdToken1.toNumber(),
        liquidationThreshold: liquidationThresholdToken1.toNumber(),
        ...fetchIconSymbolAndName(token1),
      },
      maxLeverage: maxLeverage.toNumber(),
      availableLeverage: BigNumber.max(availableLeverage0, availableLeverage1).toNumber(),
      marketReferenceCurrencyValue: marketReferenceCurrencyValue.toNumber(),
      collateral,
      poolPrice,
      invertedPoolPrice,
      feeAmount,
    };
  }
};

export const selectEnrichedUniswapPositions = (
  state: RootStore,
  positions: IPosition[]
): IEnrichedUniswapPosition[] => {
  return positions.reduce<IEnrichedUniswapPosition[]>((acc, position) => {
    const enrichedPosition = selectEnrichedUniswapPosition(state, position);
    if (enrichedPosition) {
      acc.push(enrichedPosition);
    }
    return acc;
  }, []);
};


export const selectEnrichedLeveragedUniswapPosition = (
  state: RootStore,
  positions: ILeveragedPosition[],
): IEnrichedUniswapPosition[] => {
  return positions.reduce<IEnrichedUniswapPosition[]>((acc, position) => {
    const enrichedPosition = selectEnrichedUniswapPosition(state, position.uniswapV3Position);
    const currentReserves = selectCurrentReserves(state);
    const debtToken = currentReserves.find(
      ({ underlyingAsset }) => toChecksumAddress(underlyingAsset) === position.debtAsset
    );
    if (enrichedPosition && debtToken) {
      const debt = valueWithDecimals(position.debt, debtToken.decimals);

      const revenueFeeToken0 = valueWithDecimals(position.revenueFee0, enrichedPosition.token0.decimals);
      const revenueFeeToken1 = valueWithDecimals(position.revenueFee1, enrichedPosition.token1.decimals);
      const revenueFeePercent = position.revenueFeePercent;

      const feeToken0 = BigNumber(enrichedPosition.token0.fee).minus(revenueFeeToken0);
      const feeToken1 = BigNumber(enrichedPosition.token1.fee).minus(revenueFeeToken1);

      const marketReferenceCurrencyFeeToken0 = BigNumber(enrichedPosition.token0.marketReferenceCurrencyFee)
        .minus(revenueFeeToken0.multipliedBy(enrichedPosition.token0.priceInMarketReferenceCurrency));
      const marketReferenceCurrencyFeeToken1 = BigNumber(enrichedPosition.token1.marketReferenceCurrencyFee)
        .minus(revenueFeeToken1.multipliedBy(enrichedPosition.token1.priceInMarketReferenceCurrency));

      const marketReferenceCurrencyDebt = debt.multipliedBy(debtToken.priceInMarketReferenceCurrency)
        .div(BigNumber(10).pow(USD_DECIMALS));

      const netWorthToken0 = BigNumber(enrichedPosition.token0.netWorth)
        .minus(toChecksumAddress(debtToken.underlyingAsset) === enrichedPosition.token0.underlyingAsset ? debt : 0);

      const netWorthToken1 = BigNumber(enrichedPosition.token1.netWorth)
        .minus(toChecksumAddress(debtToken.underlyingAsset) === enrichedPosition.token1.underlyingAsset ? debt : 0);

      const borrowApy = BigNumber(
        calculateCompoundedRate({ rate: debtToken.variableBorrowRate, duration: SECONDS_PER_YEAR }).toString()
      ).div(BigNumber(10).pow(25)).div(100);

      const healthFactor = BigNumber(enrichedPosition.marketReferenceCurrencyValue)
          .multipliedBy(enrichedPosition.liquidationThreshold)
          .div(marketReferenceCurrencyDebt);

      const lowHealthFactor =
        BigNumber(enrichedPosition.marketReferenceCurrencyDebt || 0)
          .div(enrichedPosition.collateral || 0)
          .isGreaterThanOrEqualTo(enrichedPosition.ltv);


      acc.push({
        ...enrichedPosition,
        type: EPositionType.LeveragedPosition,
        positionAddress: position.positionAddress,
        token0: {
          ...enrichedPosition.token0,
          fee: feeToken0.toNumber(),
          marketReferenceCurrencyFee: marketReferenceCurrencyFeeToken0.toNumber(),
          netWorth: netWorthToken0.toNumber(),
          netWorthUsd: netWorthToken0
            .multipliedBy(enrichedPosition.token0.priceInMarketReferenceCurrency)
            .toNumber(),
        },
        token1: {
          ...enrichedPosition.token1,
          fee: feeToken1.toNumber(),
          marketReferenceCurrencyFee: marketReferenceCurrencyFeeToken1.toNumber(),
          netWorth: netWorthToken1.toNumber(),
          netWorthUsd: netWorthToken1
            .multipliedBy(enrichedPosition.token1.priceInMarketReferenceCurrency)
            .toNumber(),
        },
        debtToken,
        debtAsset: toChecksumAddress(position.debtAsset),
        debtWithoutDecimals: position.debt,
        marketReferenceCurrencyDebt: marketReferenceCurrencyDebt.toNumber(),
        debt: debt.toNumber(),
        revenueFee0: revenueFeeToken0.toFixed(),
        revenueFee1: revenueFeeToken1.toFixed(),
        borrowApy: borrowApy.toNumber(),
        healthFactor: healthFactor.toNumber(),
        revenueFeePercent,
        lowHealthFactor,
      });
    }
    return acc;
  }, []);
};
