import BigNumber from 'bignumber.js';
import { USD_DECIMALS } from '@yldr/math-utils';

export const calculateInMarketReferenceCurrency = (
  amount: string,
  decimals: number,
  price: string,
) => BigNumber(amount)
  .div(BigNumber(10).pow(decimals))
  .multipliedBy(
    BigNumber(price).div(BigNumber(10).pow(USD_DECIMALS))
  );
